const initialState = {
    files: [],
    fileProgress: [],
    error: null,
  };
  
  const fileReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'UPDATE_FILE_PROGRESS':
        let fileProgress = state.fileProgress;
        const data = action.payload;
        const findIndex = fileProgress.findIndex((fp) => {
          return fp.file.name === data.file.name;
        });
        if (findIndex === -1) {
          if (!data.complete) {
            fileProgress.push(data);
          }
        } else {
          if (data.complete) {
            fileProgress.splice(findIndex, 1);
          } else {
            fileProgress[findIndex] = data;
          }
        }

        return {
          ...state,
          fileProgress: [...fileProgress],
          error: null,
        }
      case 'GET_FILES_SUCCESS':
        return {
          ...state,
          files: action.payload,
          error: null,
        };
      case 'GET_FILES_ERROR':
        return {
          ...state,
          files: [],
          error: action.payload,
        };
      case 'UPLOAD_FILE_SUCCESS':
        return {
          ...state,
          // files: [...state.files, action.payload],
          error: null,
        };
      case 'UPLOAD_FILE_ERROR':
        return {
          ...state,
          error: action.payload,
        };
      case 'DELETE_FILE_SUCCESS':
        console.log("HERE: ", action.payload);
        const updatedFiles = state.files.filter(file => file.id !== action.payload);
        return {
          ...state,
          files: updatedFiles,
          error: null,
        };
      case 'DELETE_FILE_ERROR':
        return {
          ...state,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default fileReducer;
  