import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { Auth, Hub } from "aws-amplify";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { FaMicrosoft } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LogInputField from "../Components/InputField/LogInputField";
import PasswordInput from "../Components/InputField/PasswordInput";
import Login_img from "../Components/assets/Images/Login.png";
import { setUser } from "../redux/actions/userActions";
import authAxios from "../services/authAxios";

function SignIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [challengeResponse, setChallengeResponse] = useState("");
  const [isChallenge, setIsChallenge] = useState(false);
  const [user, setUserState] = useState(null);
  const [samlUrl, setSamlUrl] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const user = await Auth.signIn(username, password);
      if (user.challengeName === "CUSTOM_CHALLENGE") {
        setIsChallenge(true);
        setUserState(user);
      } else if (user?.signInUserSession) {
        dispatch(setUser(user.signInUserSession.getIdToken().payload));
        Cookies.set("isAuthenticated", "true");
        navigate("/home");
      }
    } catch (error) {

      let message = "";

      switch (error.code) {
        case "UserLambdaValidationException":
        case "NotAuthorizedException":
          message = "Incorrect username or password.";
          break;
        case "PasswordResetRequiredException":
          message = "Password reset is required. Please reset your password.";
          break;
        case "UserNotConfirmedException":
          message =
            "Account not confirmed. Please check your email to confirm your account.";
          break;
        case "TooManyRequestsException":
          message = "Too many requests. Please try again later.";
          break;
        default:
          message = "An unknown error occurred. Please try again.";
      }

      toast.info(message);
    }
  };

  const handleChallengeSubmit = async (e) => {
    e.preventDefault();

    try {
      const loggedInUser = await Auth.sendCustomChallengeAnswer(
        user,
        challengeResponse
      );
      if (loggedInUser?.signInUserSession) {
        dispatch(setUser(loggedInUser.signInUserSession.getIdToken().payload));
        Cookies.set("isAuthenticated", "true");
        navigate("/home");
      } else {
        toast.warning("Invalid code. Please try again.");
      }
    } catch (error) {
      toast.info(`${error?.message}`);
    }
  };

  const redirectSaml = () => {
    window.location.href = samlUrl;
  };

  const checkUsername = async (event) => {
    setSamlUrl(undefined);
    const response = await authAxios.post('/check-email', { email: username });
    if (response.data.valid && response.data.response) {
      const redirectUrl = `${window.location.protocol}//${window.location.host}`;
      const fullUrl = `https://${process.env.REACT_APP_POOL_NAME}.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=${response.data.response}&redirect_uri=${redirectUrl}/home&response_type=CODE&client_id=${process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID}&scope=email openid phone`
      setSamlUrl(fullUrl);
    }
  };

  const handleNameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePassChange = (event) => {
    setPassword(event.target.value);
  };

  const handleChallengeChange = (event) => {
    setChallengeResponse(event.target.value);
  };

  const googleSignIn = () => {
    Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
    });
  };

  const microsoftSignIn = () => {
    Auth.federatedSignIn({
      provider: "Microsoft",
    });
  };

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "cognitoHostedUI":
        case "signIn":
          console.log("user signed in.");
          // let idToken = data.signInUserSession.getIdToken();
          // dispatch(setUser(idToken?.payload));
          // Cookies.set("isAuthenticated", "true");
          // dispatch(callSignIn());
          // navigate("/dashboard1");
          break;
        case "signOut":
          Cookies.remove("isAuthenticated");
          setUser(null);
          break;
        default:
          // set custom state
          break;
      }
    });

    return unsubscribe;
  }, [dispatch, navigate]);

  return (
    <div className="logIn_page">
      <div className="row">
        <div className="col-lg-7 col-md-12 col-sm-12">
          <div className="login_form">
            <div className="login_header">
              <Link to="/">
                <h2>DocuBark</h2>
              </Link>
              <div className="lang_selector">
                <p>
                  Don&apos;t have an account? <Link to="/Signup">Sign up!</Link>
                </p>
              </div>
            </div>
            <div className="form_area">
              <div className="mb-5">
                <h1>Welcome Back</h1>
                <p className="mt-2 desc">
                  <b>Login into your account</b>
                </p>
              </div>
              {!isChallenge ? (
                <form className="input_form" onSubmit={handleSubmit}>
                  <LogInputField
                    type="text"
                    name="username"
                    value={username}
                    placeholder="Username or email"
                    onChange={handleNameChange}
                    onBlur={checkUsername}
                  />
                  {!samlUrl ? (
                    <div>
                      <PasswordInput
                        name="password"
                        type="password"
                        value={password}
                        placeholder="Password"
                        onChange={handlePassChange}
                      />
                      <div className="forget_area">
                        <span className="remember_me">
                          <input type="checkbox" className="redio_input" />
                          <p>Remember me</p>
                        </span>
                        <Link to="/forgot-password" className="fuP">
                          Forgot Password?
                        </Link>
                      </div>
                      <button className="btn" type="submit">
                        Sign In
                      </button>
                    </div>
                  ) : (
                      <div>
                        <button className="btn" onClick={redirectSaml}>
                          Login with SAML
                        </button>
                      </div>
                    )}
                </form>
              ) : (
                <form className="input_form" onSubmit={handleChallengeSubmit}>
                  <h3>Enter the code sent to your email</h3>
                  <LogInputField
                    type="text"
                    name="challengeResponse"
                    value={challengeResponse}
                    placeholder="Enter your code here."
                    onChange={handleChallengeChange}
                  />
                  <button className="btn" type="submit">
                    Submit Code
                  </button>
                </form>
              )}
              {!samlUrl ? (
              <div className="social_media">
                <p>Or</p>
                <div className="social_group">
                  <div
                    className="social_group_btn"
                    style={{ width: "100%" }}
                    onClick={googleSignIn}
                  >
                    <FcGoogle className="icons" />
                    <span style={{ display: "block" }}>
                      Continue with Google
                    </span>
                  </div>
                  <div
                    className="social_group_btn"
                    style={{ width: "100%" }}
                    onClick={microsoftSignIn}
                  >
                    <FaMicrosoft className="icons" />
                    <span style={{ display: "block" }}>
                      Continue with Microsoft
                    </span>
                  </div>
                  {/* <div className="social_group_btn">
                    <FaFacebook className="icons fb_icon" />
                    <span style={{ display: "block" }}>Facebook</span>
                  </div> */}
                </div>
              </div>
              ) : ( '' ) }
            </div>
            <div className="logIn_footer">
              <Link to="/privacy-policy">Privacy Policy</Link>
              <span>|</span>
              <Link to="/tos">Terms of Service</Link>
              <p>Copyright 2023</p>
            </div>
          </div>
        </div>
        <div className="col-lg-5 col-md-12 col-sm-12">
          <div className="login_img">
            <img src={Login_img} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
