const initialState = {
  libraries: [],
  error: null,
  updatingLibraries: false,
};

const libraryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_LIBRARY_SUCCESS":
      return {
        ...state,
        libraries: action.payload,
        error: null,
      };
    case "GET_LIBRARY_ERROR":
      return {
        ...state,
        libraries: [],
        error: action.payload,
      };
    case "CREATE_LIBRARY_SUCCESS":
      return {
        ...state,
        libraries: [...state.libraries, action.payload],
        error: null,
      };
    case "CREATE_LIBRARY_ERROR":
      return {
        ...state,
        error: action.payload,
      };
    case "UPDATE_LIBRARY_START":
      return {
        ...state,
        updatingTemplate: true,
      };
    case "UPDATE_LIBRARY_SUCCESS":
      const updatedTemplatesAfterUpdate = state.libraries.map((template) => {
        if (template.id === action.payload.id) {
          return action.payload;
        }
        return template;
      });
      return {
        ...state,
        libraries: updatedTemplatesAfterUpdate,
        error: null,
        updatingTemplate: false,
      };
    case "UPDATE_LIBRARY_ERROR":
      return {
        ...state,
        error: action.payload,
      };
    case "DELETE_LIBRARY_SUCCESS":
      const updatedTemplates = state.libraries.filter(
        (template) => template.id !== action.payload.id
      );
      return {
        ...state,
        libraries: updatedTemplates,
        error: null,
      };
    case "DELETE_LIBRARY_ERROR":
      return {
        ...state,
        error: action.payload,
      };
    case "ADD_LIBRARY_SUCCESS":
      return {
        ...state,
        error: null,
      };
    case "ADD_LIBRARY_ERROR":
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default libraryReducer;
