const awsmobile = {
  Auth: {
    region: process.env.REACT_APP_AUTH_REGION,
    userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
    authenticationFlowType: "CUSTOM_AUTH",
    cookieStorage: {
      domain: process.env.REACT_APP_DOMAIN_NAME,
      path: "/",
      expires: 1,
      sameSite: "strict",
      secure: true,
    },
    oauth: {
      domain: `${process.env.REACT_APP_POOL_NAME}.auth.us-east-1.amazoncognito.com`,
      scope: ["email", "openid"],
      redirectSignIn: `${window.location.origin}/home`,
      redirectSignOut: `${window.location.origin}/`,
      responseType: "code", // or 'token',
      federationTarget: "COGNITO_USER_POOLS",
      providers: ["Google", "Microsoft"],
    },
  },
};

export default awsmobile;
