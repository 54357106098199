import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function DashboardHome() {
  const navigate = useNavigate();

  useEffect(()=>{
    navigate("/questionnaire");
  }, [navigate])

  return null;
}
