import { Button, Checkbox, List, Modal } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFiles } from "../../redux/actions/fileActions";

export default function QuestionnaireRunModal({selectedFileIds, setSelectedFileIds, onSubmit}) {
    const dispatch = useDispatch();
    const allFiles = useSelector(state => state.file?.files);

    useEffect(() => {
        dispatch(getFiles());
    }, [dispatch]);

  return <>
    <List
        size="small"
        header={<strong>Select Files</strong>}
        bordered
        dataSource={allFiles}
        renderItem={(value) => <List.Item>
            <Checkbox
                style={{marginRight: ".5rem"}}
                value={selectedFileIds.includes(value.id)}
                onClick={()=>{
                    if (selectedFileIds.includes(value.id)) {
                        setSelectedFileIds(selectedFileIds.filter(v=>v!==value.id))
                    } else {
                        setSelectedFileIds([...selectedFileIds, value.id])
                    }
                }}
            />
            <div style={{color: "black", width: "100%"}}>{value.source_filename}</div>
        </List.Item>}
    />
    <Button type="primary" style={{marginLeft: "auto"}} onClick={onSubmit}>Run</Button>
  </>
}