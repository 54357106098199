import React, { useEffect, useState } from "react";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import { useDispatch } from "react-redux";
import ChatInterface from "../Components/ChatInterface/ChatInterface";
import FileUploadForm from "../Components/ImageUpload/FileUploadForm";
import DocumentsTable from "../Components/Table/DocumentsTable";
import { updateReceiptData } from "../redux/actions/receiptActions";

function Dashboard() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateReceiptData(null));
  }, [dispatch]);

  return (
    <div className="dashboard_page">
      <div className="row">
        <div>
          <ChatInterface />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
