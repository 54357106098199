import React, { useEffect, useState } from "react";
import { Form, Space, Table, Typography, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getTeam,
  getTeamMembers,
  putTeam,
} from "../redux/actions/adminTeamsAction";
import {
  MdInfoOutline,
  MdAdminPanelSettings,
  MdSupervisedUserCircle,
} from "react-icons/md";
import { useParams } from "react-router-dom";
import ChatLoader from "../Components/ChatInterface/ChatLoader";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const TeamDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const { currentTeam, currentTeamMembers, fetchingTeam, fetchingTeamMembers } =
    useSelector((state) => state.adminTeams);

  useEffect(() => {
    dispatch(getTeam(id));
    dispatch(getTeamMembers(id));
  }, [dispatch]);

  const enterpriseChange = () => {
    currentTeam.team.enterprise = !currentTeam.team.enterprise;
    dispatch(putTeam(currentTeam.team))
      .then(() => {
        toast.success(`Team updated`, {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 2000,
        });
        dispatch(getTeam(id));
      })
      .catch((error) => {
        console.error("Team update error", error);
        toast.info(`${error}`, {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 2000,
        });
      });
  };

  const handleViewMore = (record) => {
    navigate(`/user/${record?.user_id}`);
  };

  const tableProps = {
    loading: fetchingTeamMembers || fetchingTeam,
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: "5%",
      editable: false,
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "25%",
      editable: false,
    },
    {
      title: "Role",
      dataIndex: "role",
      width: "15%",
      editable: true,
      inputType: "select",
      render: (_, tag) => {
        return (
          <>
            {
              <Tag
                color={"white"}
                style={{
                  width: "85%",
                  backgroundColor: "#3da58a",
                  fontSize: "10px !important",
                  borderRadius: "4px",
                }}
              >
                <p
                  style={{
                    padding: "3px",
                    fontSize: "13px ",
                    paddingLeft: "21%",
                  }}
                >
                  {tag?.role === "admin" ? (
                    <MdAdminPanelSettings
                      style={{ fontSize: "13px", margin: "0 5px" }}
                    />
                  ) : (
                    <MdSupervisedUserCircle
                      style={{ fontSize: "13px", margin: "0 5px" }}
                    />
                  )}
                  {tag?.enable_2fa !== null ? tag?.role : "user"}
                </p>
              </Tag>
            }
          </>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      editable: false,
    },
    {
      title: "Last Used",
      dataIndex: "last_used",
      editable: false,
    },
    {
      title: "Last Login",
      dataIndex: "last_login",
      editable: false,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => {
        return record?.status === "active" ? (
          <Space>
            <Typography.Link
              className="btn btn_hover"
              onClick={() => handleViewMore(record)}
            >
              <MdInfoOutline color="#fff" size={15} />
            </Typography.Link>
          </Space>
        ) : null;
      },
    },
  ];

  useEffect(() => {
    if (currentTeamMembers) {
      const newData = currentTeamMembers.map((member, index) => {
        return {
          id: index + 1,
          key: index + 1,
          user_id: `${member?.user_id}`,
          email: member?.email,
          role: member?.role,
          status: member?.membership_status,
          enable_2fa: member?.enable_2fa,
          last_used: member?.last_used,
          last_login: member?.last_login,
        };
      });
      setData(newData);
    }
  }, [currentTeamMembers]);

  return (
    <>
      {fetchingTeam || fetchingTeamMembers ? (
        <ChatLoader />
      ) : (
        <div>
          <h1>Team Name: {currentTeam?.team?.name}</h1>
          <br />
          <div className="container">
            <div className="row">
              <div className="col">
                <h2>
                  Total Members in Team: {currentTeamMembers?.length || 0}
                </h2>
                <h2>
                  Total Question asked by Team:{" "}
                  {currentTeam?.num_questions || 0}
                </h2>
              </div>
              <div className="col text-end">
                <button className="btn" onClick={enterpriseChange}>
                  {currentTeam?.team?.enterprise
                    ? "Make Regular Team"
                    : "Make Enterprise Team"}
                </button>
              </div>
            </div>
          </div>
          <br />
          <Form form={form} component={false}>
            <Table
              {...tableProps}
              dataSource={data}
              columns={columns}
              rowClassName="editable-row"
            />
          </Form>
        </div>
      )}
    </>
  );
};

export default TeamDetails;
