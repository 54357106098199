import { Button, Layout, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineLogout } from "react-icons/ai";
import { BiMenu, BiX } from "react-icons/bi";
import { IoMdSettings } from "react-icons/io";
import { IoDocumentsOutline } from "react-icons/io5";
import { RxChatBubble, RxDashboard } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { getUser, signOut } from "../../redux/actions/authActions";
import "./Navbar.css";
import Topbar from "./Topbar";


const { Header, Sider, Content } = Layout;

const MainLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);

  const mainMenuItems = [
    {
      key: "questionnaire",
      icon: <RxDashboard />,
      label: "Questionnaire",
    },
    {
      key: "chat",
      icon: <RxChatBubble />,
      label: "Chat",
    },
    {
      key: "knowledge-base",
      icon: <IoDocumentsOutline />,
      label: "Knowledge Base",
    },
    {
      key: "settings",
      icon: <IoMdSettings />,
      label: "Settings",
    }
  ]

  const mainMenuItems2 = [
    {
      key: "logout",
      icon: <AiOutlineLogout />,
      label: "Log Out",
    },
  ];

  const [toggleBar, setToggleBar] = useState(false);
  const [activeItem, setActiveItem] = useState(false);

  useEffect(() => {
    const currentPath = location.pathname.split("/").slice(1).join("/");
    setActiveItem(currentPath);
  }, [location.pathname]);

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  const handleMenuItemClick = (itemKey) => {
    if (itemKey === "logout") {
      dispatch(signOut());
    } else {
      setActiveItem(itemKey);
      navigate(`/${itemKey}`);
    }
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        trigger={null}
        className={toggleBar ? "hide_side_navbar" : "side_navbar"}
      >
        <div className="demo-logo-vertical logo">
          <h2>DocuBark</h2>
        </div>
        <div className="side_bar_menu_list">
          <div className="sidebar_top">
            <Menu theme="dark" mode="inline" selectedKeys={[activeItem]}>
              {mainMenuItems.map((menuItem) => {
                return (
                  <Menu.Item
                    key={menuItem.key}
                    icon={menuItem.icon}
                    onClick={() => handleMenuItemClick(menuItem.key)}
                  >
                    <div className={"sidebar_menu_item"}>
                      <span>{menuItem.label}</span>
                    </div>
                  </Menu.Item>
                );
              })}
            </Menu>
            {!userData?.team?.enterprise ? (
              <div className="nav_credits_area">
                <div className="info">
                  <p>Credits</p>
                  <p>{userData ? parseInt(userData?.credit) : 0}</p>
                </div>
                <Link to="/buy-credits">
                  <button className="btn">Buy Credits</button>
                </Link>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="sidebar_bottom">
            <Menu theme="dark" mode="inline" selectedKeys={[activeItem]}>
              {mainMenuItems2.map((menuItem) => (
                <Menu.Item
                  key={menuItem.key}
                  icon={menuItem.icon}
                  onClick={() => handleMenuItemClick(menuItem.key)}
                >
                  {menuItem.label}
                </Menu.Item>
              ))}
            </Menu>
            <hr />
            <div className="sidenav_user">
              <Link to="/tos">Terms</Link>
              <span>|</span>
              <Link to="/privacy-policy">Privacy</Link>
            </div>
          </div>
        </div>
      </Sider>
      <Layout className={toggleBar ? "hide_body_layout" : "body_layout"}>
        <Header
          className="fixed-header"
          style={{
            padding: 0,
            minHeight: 65,
          }}
        >
          <Button
            type="text"
            className="header_toggle_btn"
            icon={
              toggleBar ? (
                <BiX className="icons" />
              ) : (
                <BiMenu className="icons" />
              )
            }
            onClick={() => setToggleBar(!toggleBar)}
          />
          <Topbar />
        </Header>
        <Content
          // className="body_content"
          style={{
            // margin: "24px 24px",
            padding: 24,
            minHeight: 100,
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
