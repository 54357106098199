import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    DownloadQuestionnaire,
    getQuestionnaires,
    RemoveQuestionnaire,
    ShowQuestionnaire,
    StartQuestionnaireRun
} from "../redux/actions/questionnaireActions";
import QuestionnaireForm from "../Components/DialogBox/QuestionnaireForm";
import {Table, Form, List, ConfigProvider, Button, Select, Collapse, Tooltip, Tag, Modal, Input, Checkbox} from "antd";
import {MdDeleteOutline, MdMenu, MdListAlt } from "react-icons/md";
import {SlArrowLeft, SlArrowRight} from "react-icons/sl";
import DocumentsTable from "../Components/Table/DocumentsTable";
import FileUploadForm from "../Components/ImageUpload/FileUploadForm";
import {toast} from "react-toastify";
import _ from 'lodash';
import { formatDistanceToNow } from 'date-fns';
import {BiDownload} from "react-icons/bi";
import QuestionnaireRunModal from "../Components/DialogBox/QuestionnaireRunModal";

function Questionnaire() {
    const dispatch = useDispatch();
    const [modifiedQuestionnaires, setModifiedQuestionnaires] = useState([]);
    const [selectedFileIds, setSelectedFileIds] = useState([]);
    const [uploadTabs, setUploadTabs] =useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedQuestionnaire, setSelectedQuestionnaire] = useState(undefined);
    const [runName, setRunName] = useState('');
    const [form] = Form.useForm();
    const handleSelectedFiles = (selectedFileIds) => {
        setSelectedFileIds(selectedFileIds);
    };

    const questionnaires = useSelector(state => state.questionnaire.questionnaires);
    const showForm = useSelector(state => state.questionnaire.showForm);
    const setShowForm = (val) => {
        dispatch({ type: 'SHOW_QUESTIONNAIRE_FORM', payload: val })
    }

    const createClick = () => {
        dispatch({ type: 'CLEAR_QUESTIONNAIRE_FORM' })
        setShowForm(true);
    }

    useEffect(() => {
        const statusInterval  = setInterval(() => {
            dispatch(getQuestionnaires());
        }, 10000);
        dispatch(getQuestionnaires());

        return () => {
            clearInterval(statusInterval);
        };
    }, []);

    useEffect(() => {
        const questionnairesTemp = questionnaires.map((questionnaire) => {
           questionnaire.sheets_count = questionnaire.sheets.length;
           let count = 0;
           questionnaire.sheets.forEach(sheet => {
              count += sheet.questions.length;
           });
           questionnaire.questions_count = count;
           return questionnaire;
        });
        setModifiedQuestionnaires(questionnairesTemp);
    }, [questionnaires]);

    const showQuestionnaire = (questionnaire) => {
        dispatch(ShowQuestionnaire(questionnaire));
        setShowForm(true);
    };

    const removeQuestionnaire = (questionnaire) => {
        dispatch(RemoveQuestionnaire(questionnaire));
    };

    const columns = [
        {
            title: 'Questionnaire',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Sheets',
            dataIndex: 'sheets_count',
            key: 'sheets_count',
        },
        {
            title: 'Questions',
            dataIndex: 'questions_count',
            key: 'questions_count',
        },
        {
            title: 'Actions',
            align: 'center',
            render: (i, record) => (
                <div key={`actions-${i}`}>
                    <Tooltip title="View Questions">
                        <a onClick={() => { showQuestionnaire(record) }}>
                            <MdListAlt style={{ fontSize: '25px' }} />
                        </a>
                    </Tooltip>
                    <Tooltip title="Remove Questionnaire">
                        <a onClick={() => { removeQuestionnaire(record) }}>
                            <MdDeleteOutline style={{ fontSize: '25px' }} />
                        </a>
                    </Tooltip>
                </div>
            )
        },
        {
            title: 'Get Answers',
            align: 'center',
            render: (_, record) => {
                if (!record.runs || record.runs.filter(r => r.status !== 'Complete').length === 0) {
                    return (<Button size="small" onClick={() => {
                        runQuestionnaire(record)
                    }}>Run Now</Button>)
                } else {
                    return ( <Tag className="text-center" color="green">Running ({record.runs.length}) <br /> {questionCount(record)}</Tag> )
                }
            }
        },
        {
            title: 'Download',
            align: 'left',
            render: (_, record) => {
                return (<table className="downloadTable">
                    <thead><tr><th>Name</th><th>Files Used</th><th> </th></tr></thead>
                    <tbody>
                        {listDownloads(record)}
                    </tbody>
                    </table>)
            }
        }
    ];

    const listFiles = (names) => {
        return (
            <ul className="fileList">
                {names.map((name) => {
                    return (<li>{name}</li>)
                })}
            </ul>
        )
    }

    const listDownloads = (questionnaire) => {
        if (!questionnaire.runs || questionnaire.runs.length === 0) {
            return '';
        }
        return questionnaire.runs.map((run) => {
            if (run.status === 'Complete') {
                return ( <tr>
                    <td>{run.name}</td>
                    <td>
                        <Tooltip title={listFiles(run.usertraindata_names)}>
                            <span className="viewFiles">View</span>
                        </Tooltip>
                    </td>
                    <td>
                        <Tooltip title="Download Completed Excel Sheet">
                            <BiDownload
                                className="icons mouseCursor"
                                onClick={() => { downloadFile(questionnaire, run.id) }}
                            />
                        </Tooltip>
                    </td>
                </tr> )
            } else {
                return (<span></span>)
            }
        })
    };

    const downloadFile = async (questionnaire, run_id) => {
        toast.info("preparing download", {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 2000
        })
        dispatch(DownloadQuestionnaire(questionnaire, run_id));
    };

    const questionCount = (questionnaire) => {
        if (questionnaire.runs.length === 0) {
            return '';
        }
        let totalQuestions = 0;
        let answeredQuestions = 0;
        let lastRun;

        if (questionnaire.runs.length > 1) {
            const sortedRuns = _.orderBy(questionnaire.runs, ['created_at'], ['desc']);
            lastRun = sortedRuns[0];
        } else {
            lastRun = questionnaire.runs[0];
        }

        totalQuestions += lastRun.question_responses.length;
        answeredQuestions += lastRun.question_responses.filter((qr) => qr.status === 'Answered').length;
        return `${answeredQuestions} of ${totalQuestions} Questions Complete`;
    }

    const runQuestionnaire = async (questionnaire) => {
        setSelectedQuestionnaire(questionnaire);
        setIsModalOpen(true);
    };

    const handleOk = async (values) => {
        setIsModalOpen(false);
        toast.info("Please wait a moment for the questionnaire to start processing.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 2000
        })
        await dispatch(StartQuestionnaireRun(selectedQuestionnaire, selectedFileIds, new Date()))
        dispatch(getQuestionnaires());
        toast.info("Questionnaire is being processed, this may take a few minutes.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 2000
        })
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    }

    return (
        <div className="dashboard_page">
            <Modal zIndex='1000' title='Start new run' open={isModalOpen} onCancel={handleCancel}>
                <QuestionnaireRunModal
                    selectedFileIds={selectedFileIds}
                    setSelectedFileIds={setSelectedFileIds}
                    onSubmit={handleOk}
                />
            </Modal>
            <div className="row">
                <div>
                    <div className="d-flex flex-row w-100 align-items-center">
                        <div className="flex-grow-1">
                            <h3>Questionnaires</h3>
                        </div>
                        <div className="text-end">
                            <button className="btn" onClick={createClick}>Add Questionnaire</button>
                        </div>
                    </div>
                    <QuestionnaireForm isModalOpen={showForm} setIsModalOpen={setShowForm} />
                    <div className="mt-2">
                        <Table columns={columns} dataSource={modifiedQuestionnaires} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Questionnaire;
