import React, { useEffect, useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ChatLoader from "../ChatInterface/ChatLoader";
import { getSettings, updateSettings } from "../../redux/actions/settingsActions";

export const MainSettings = () => {
  const dispatch = useDispatch();
  const {
    fetching,
    customPrompt,
    customModelEnabled,
    evidenceFormattingEnabled,
    questionnairesEnabled,
    evidenceFormattingPrompt,
    useSearchAPI: _useSearchAPI,
    showLibrary: _showLibrary,
  } = useSelector((state) => state.settings);
  const [editMode, setEditMode] = useState(false);
  const [efpEditMode, setEfpEditMode] = useState(false);
  const [useSearchAPI, setUseSearchAPI] = useState(_useSearchAPI);
  const [showLibrary, setShowLibrary] = useState(_showLibrary);
  const [prompt, setPrompt] = useState(customPrompt);
  const [efPrompt, setEfPrompt] = useState(evidenceFormattingPrompt);
  const [customModel, setCustomModel] = useState(customModelEnabled);
  const [isEvidenceFormattingEnabled, setIsEvidenceFormattingEnabled] =
    useState(evidenceFormattingEnabled);
  const [isQuestionnairesEnabled, setIsQuestionnairesEnabled] = useState(false);
  const [defaultPrompt, setDefaultPrompt] =
    useState(`You are a chatbot assisting in a conversation with a human.

  Using both your built-in knowledge and the following extracted parts of a long document, please provide an answer to the given question.

  Your answer should be as detailed as possible if necessary.

  If the document does not contain relevant information for answering the question, please make that clear in your response.`);
  useEffect(() => {
    dispatch(getSettings());
  }, []);

  useEffect(() => {
    setPrompt(customPrompt);
  }, [customPrompt]);

  useEffect(() => {
    setShowLibrary(_showLibrary);
  }, [_showLibrary]);

  useEffect(() => {
    setUseSearchAPI(_useSearchAPI);
  }, [_useSearchAPI]);

  useEffect(() => {
    setEfPrompt(evidenceFormattingPrompt);
  }, [evidenceFormattingPrompt]);

  useEffect(() => {
    setCustomModel(customModelEnabled);
  }, [customModelEnabled]);

  useEffect(() => {
    setIsEvidenceFormattingEnabled(evidenceFormattingEnabled);
  }, [evidenceFormattingEnabled]);

  useEffect(() => {
    setIsQuestionnairesEnabled(questionnairesEnabled);
  }, [questionnairesEnabled]);

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleCancelClick = () => {
    setEditMode(false);
    setPrompt(customPrompt);
  };

  const handlePromptChange = (event) => {
    setPrompt(event.target.value);
  };

  const handleEfpEditClick = () => {
    setEfpEditMode(true);
  };

  const handleEfpCancelClick = () => {
    setEfpEditMode(false);
    setEfPrompt(evidenceFormattingPrompt);
  };

  const handleEfpPromptChange = (event) => {
    setEfPrompt(event.target.value);
  };

  const handleCustomModelChange = (event) => {
    setCustomModel(event.target.checked);
    dispatch(updateSettings({ custom_model: event.target.checked })).catch(
      (error) => {
        toast.error(error.message);
      }
    );
  };

  const handleEvidenceFormattingEnabledChange = (event) => {
    setIsEvidenceFormattingEnabled(event.target.checked);
    dispatch(updateSettings({ ef_enabled: event.target.checked })).catch(
      (error) => {
        toast.error(error.message);
      }
    );
  };

  const handleQuestionnairesEnabledChange = (event) => {
    setIsQuestionnairesEnabled(event.target.checked);
    dispatch(updateSettings({ questionnaires_enabled: event.target.checked })).catch(
      (error) => {
        toast.error(error.message);
      }
    );
  };

  const handleUseSearchAPI = (event) => {
    setUseSearchAPI(event.target.checked);
    dispatch(updateSettings({ use_search_api: event.target.checked })).catch(
      (error) => {
        toast.error(error.message);
      }
    );
  };

  const handleShowLibrary = (event) => {
    setShowLibrary(event.target.checked);
    dispatch(updateSettings({ show_library: event.target.checked })).catch(
      (error) => {
        toast.error(error.message);
      }
    );
  };

  const handleSaveClick = () => {
    // if (!prompt) return toast.error("Prompt cannot be empty");
    if (!prompt) setPrompt(defaultPrompt);

    dispatch(updateSettings({ custom_prompt: prompt }))
      .then(() => {
        setEditMode(false);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const handleEfpSaveClick = () => {
    // if (!efPrompt) return toast.error("Prompt cannot be empty");

    dispatch(updateSettings({ evidence_formatting_prompt: efPrompt }))
      .then(() => {
        setEfpEditMode(false);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const handleResetPromptToDefault = () => {
    dispatch(updateSettings({ custom_prompt: null })).catch((error) => {
      toast.error(error.message);
    });
  };

  const handleResetEvidencePromptToDefault = () => {
    dispatch(updateSettings({ evidence_formatting_prompt: null })).catch(
      (error) => {
        toast.error(error.message);
      }
    );
  };

  return (
    <div className="">
      <div className="section_title">
        <h2>Main Settings</h2>
      </div>
      <section className="prompt-section">
        <div className="prompt-header">
          <h3>Prompt</h3>
          {!editMode && (
            <FiEdit2 onClick={handleEditClick} className="edit-prompt-icon" />
          )}
        </div>
        {editMode ? (
          <div className="prompt-edit">
            <textarea
              type="text"
              value={prompt}
              rows="10"
              cols="60"
              onChange={handlePromptChange}
              disabled={fetching}
            />
            <button onClick={handleSaveClick} disabled={fetching}>
              Save
            </button>
            <button onClick={handleCancelClick} disabled={fetching}>
              Cancel
            </button>
          </div>
        ) : (
          <div className="prompt-display">
            {fetching ? <ChatLoader /> : <span>{prompt}</span>}
          </div>
        )}
      </section>
      <section className="enable-ef-section">
        <h3>Evidence Formatting Enabled</h3>
        <div className="enable-ef-switch">
          <label
            className="switch"
            onChange={handleEvidenceFormattingEnabledChange}
          >
            <input type="checkbox" checked={isEvidenceFormattingEnabled} />
            <span className="slider round"></span>
          </label>
        </div>
      </section>
      <section className="enable-ef-section">
        <h3>Questionnaires Enabled</h3>
        <div className="enable-ef-switch">
          <label
            className="switch"
            onChange={handleQuestionnairesEnabledChange}
          >
            <input type="checkbox" checked={isQuestionnairesEnabled} />
            <span className="slider round"></span>
          </label>
        </div>
      </section>

      <section className="prompt-section">
        <div className="prompt-header">
          <h3>Evidence Formatting Prompt</h3>
          {!efpEditMode && (
            <FiEdit2
              onClick={handleEfpEditClick}
              className="edit-prompt-icon"
            />
          )}
        </div>
        {efpEditMode ? (
          <div className="prompt-edit">
            <textarea
              type="text"
              value={efPrompt}
              rows="10"
              cols="60"
              onChange={handleEfpPromptChange}
              disabled={fetching}
            />
            <button onClick={handleEfpSaveClick} disabled={fetching}>
              Save
            </button>
            <button onClick={handleEfpCancelClick} disabled={fetching}>
              Cancel
            </button>
          </div>
        ) : (
          <div className="prompt-display">
            {fetching ? <ChatLoader /> : <span>{efPrompt}</span>}
          </div>
        )}
      </section>
      <button className="reset" onClick={handleResetPromptToDefault}>
        Reset Prompt to Default
      </button>
      <button className="reset" onClick={handleResetEvidencePromptToDefault}>
        Reset Evidence Formatting Prompt to Default
      </button>

      <section className="custom-model-section">
        <h3>GPT 3.5</h3>
        <div className="custom-model-switch">
          <label className="switch" onChange={handleCustomModelChange}>
            <input type="checkbox" checked={customModel} />
            <span className="slider round"></span>
          </label>
        </div>
        <h3>GPT 4</h3>
      </section>

      <section className="enable-ef-section">
        <h3>Use Search API</h3>
        <div className="enable-ef-switch">
          <label className="switch" onChange={handleUseSearchAPI}>
            <input type="checkbox" checked={useSearchAPI} />
            <span className="slider round"></span>
          </label>
        </div>
      </section>

      <section className="enable-ef-section">
        <h3>Enable Library</h3>
        <div className="enable-ef-switch">
          <label className="switch" onChange={handleShowLibrary}>
            <input type="checkbox" checked={showLibrary} />
            <span className="slider round"></span>
          </label>
        </div>
      </section>
    </div>
  );
};