import React, { useEffect, useState } from "react";
import { Form, Space, Table, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getTeams } from "../../redux/actions/adminTeamsAction";
import { MdInfoOutline } from "react-icons/md";
import "./users.css";
import SearchInput from "../Search/SearchInput";
import { useNavigate } from "react-router-dom";

const TeamsTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.adminUsers.fetching);
  const teams = useSelector((state) => state.adminTeams.teams);
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      credit: record?.credit,
      role: record?.role,
      enable_2fa: record?.enable_2fa,
      ...record,
    });
    setEditingKey(record?.key);
  };

  const handleViewMore = (record) => {
    navigate(`/team/${record.key}`);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: "5%",
      editable: false,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      editable: false,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      editable: false,
    },
    {
      title: "Last Used",
      dataIndex: "last_used",
      key: "last_used",
      editable: false,
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      editable: false,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          <Typography.Link
            className="btn btn_hover"
            disabled={editingKey !== ""}
            onClick={() => handleViewMore(record)}
          >
            <MdInfoOutline color="#fff" size={15} />
          </Typography.Link>
        </Space>
      ),
    },
  ];

  const tableProps = {
    loading,
  };

  const handleSearch = (term) => {
    dispatch(getTeams({ search: term }));
  };

  useEffect(() => {
    if (teams && Array.isArray(teams)) {
      setData(
        teams.map((team, index) => ({
          id: index + 1,
          key: `${team?.id}`,
          name: team?.name,
          description: team?.description,
          created_at: team?.created_at,
          last_used: team?.last_used,
        }))
      );
    } else {
      setData([]);
    }
  }, [teams]);

  return (
    <div className="users-table">
      <SearchInput onSearch={handleSearch} />
      <Form form={form} component={false}>
        <Table
          {...tableProps}
          style={{ fontSize: "8px" }}
          dataSource={data}
          columns={columns}
          rowClassName="editable-row"
        />
      </Form>
    </div>
  );
};

export default TeamsTable;
