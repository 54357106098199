import authAxios from "../../services/authAxios";
import { createChat } from "./chatActions";

export const getFiles = () => async (dispatch) => {
    try {
        const response = await authAxios.get('get-train-data');
        dispatch({ type: 'GET_FILES_SUCCESS', payload: response.data });
    } catch (error) {
        dispatch({ type: 'GET_FILES_ERROR', payload: error.message });
    }
};

export const uploadFile =
  (file, chatSpecific) => async (dispatch, getState) => {
    try {
      const { currentChat, chats } = getState().chat;
      let chatId = null; 

      if (chatSpecific && currentChat) {
        chatId = currentChat.id;
      }
      else if (chatSpecific && !currentChat) {
        const newChatId = `New Chat${chats?.length + 1}`;
        const newChat = {
          title: newChatId,
          chat_specific: chatSpecific,
          board_type: null,
        };

        const createdChat = await dispatch(createChat(newChat));

        if (createdChat) {
          chatId = createdChat.id;
          dispatch({ type: "UPDATE_CURRENT_CHAT", payload: createdChat });
        }
      }

      const formData = new FormData();
      formData.append("file", file);
      formData.append("chat_id", chatId);

      await authAxios.post("train", formData, {
        onUploadProgress: (progressEvent) => {
          dispatch({
            type: "UPDATE_FILE_PROGRESS",
            payload: {
              file,
              progressEvent,
              complete: false,
              error: "",
            },
          });
        },
      });

      dispatch({ type: "UPLOAD_FILE_SUCCESS" });
      dispatch({
        type: "UPDATE_FILE_PROGRESS",
        payload: { file, progressEvent: {}, complete: true, error: "" },
      });
      dispatch(getFiles());
    } catch (error) {
      console.error(error);
      dispatch({
        type: "UPDATE_FILE_PROGRESS",
        payload: {
          file,
          progressEvent: {},
          complete: true,
          error: error.message,
        },
      });
      dispatch({ type: "UPLOAD_FILE_ERROR", payload: error.message });
    }
  };

export const deleteFile = (data_id, file_name, customer_id) => async (dispatch) => {
    try {
        const response = await authAxios.post('delete', {data_id, file_name, customer_id});
        dispatch({ type: 'DELETE_FILE_SUCCESS', payload: data_id });
    } catch (error) {
        dispatch({ type: 'DELETE_FILE_ERROR', payload: error.message });
    }
};
