import {Button, Form, Input, Select, Switch, Upload} from "antd";
import {InboxOutlined} from "@ant-design/icons";
import React, {useState} from "react";
import {step2Questionnaire} from "../../redux/actions/questionnaireActions";
import {useDispatch, useSelector} from "react-redux";

function Step2() {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const sheets = useSelector(state => state.questionnaire.availableSheets)
    const questionnaire = useSelector(state => state.questionnaire.questionnaire);

    const colLetter = (i) => {
        const m = i % 26;
        const c = String.fromCharCode(65 + m);
        const r = i - m;
        return r > 0
            ? `${colLetter((r - 1) / 26)}${c}`
            : c
    };

    const onFinish = async (values) => {
        values.sheets = [];
        Object.keys(values).forEach(key => {
           if (key.includes('sheet.')) {
               const val = values[key];
               const sheetIndex = parseInt(key.substring(6));
               delete values[key];
               if (val) {
                   values.sheets.push(sheets[sheetIndex]);
               }
           }
        });
        await dispatch(step2Questionnaire(values, questionnaire));
        // form.resetFields();
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Form name="basic" labelCol={{span: 8}} wrapperCol={{span: 16}}
              style={{maxWidth: '70vw',}}
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout="horizontal"
        >
            <h1 className="text-black mb-2 w-100">Questionnaire {questionnaire.name} created successfully.</h1>
            <h3 className="text-black my-4">Select Sheets that have questions:</h3>
            {
                sheets.map((sheet, i) => {
                    return (
                        <Form.Item label={sheet} valuePropName="checked" key={i} name={`sheet.${i}`} initialValue={true}>
                            <Switch/>
                        </Form.Item>
                    )
                })
            }
            <div className="mycontainer">
                <h3>Where should we populate data?</h3>
                <p>Leave a column blank if you don't want to use this feature.</p>
                <Form.Item label="Yes/No Column" name="yesnoColumn">
                    <Select>
                        <Select.Option value="">None</Select.Option>
                        {
                            Array(26).fill(1).map((_, i) => {
                                return (<Select.Option value={i + 1} key={i}>{colLetter(i)}</Select.Option>)
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item label="Comment Column" name="commentsColumn">
                    <Select>
                        <Select.Option value="">None</Select.Option>
                        {
                            Array(26).fill(1).map((_, i) => {
                                return (<Select.Option value={i + 1} key={i}>{colLetter(i)}</Select.Option>)
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item label="Excerpt Column" name="excerptColumn">
                    <Select>
                        <Select.Option value="">None</Select.Option>
                        {
                            Array(26).fill(1).map((_, i) => {
                                return (<Select.Option value={i + 1} key={i}>{colLetter(i)}</Select.Option>)
                            })
                        }
                    </Select>
                </Form.Item>
            </div>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Continue
                </Button>
            </Form.Item>
        </Form>
)
}

export default Step2;
