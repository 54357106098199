const initialState = {
    questionnaires: [],
    availableSheets: [],
    error: null,
    updatingLibraries: false,
    step: 1,
    questions: [],
    sheets: [],
    questionnaire: undefined,
    showForm: false,
};

const questionnaireReducer = (state = initialState, action) => {
    const updateQuestions = [...state.questions];
    const updateQuestionnaires = [...state.questionnaires];

    switch (action.type) {
        case "GET_QUESTIONNAIRES_SUCCESS":
            return {
                ...state,
                questionnaires: action.payload,
                error: null,
            };
        case "GET_QUESTIONNAIRES_ERROR":
            return {
                ...state,
                error: action.payload,
            };
        case "UPLOAD_QUESTIONNAIRE_SUCCESS":
            return {
                ...state,
                questionnaire:  action.payload.questionnaire,
                availableSheets: action.payload.sheets,
                step: 2,
            };
        case "STEP2_QUESTIONNAIRE_SUCCESS":
            return {
                ...state,
                questions: action.payload.questions,
                sheets: action.payload.sheets,
                questionnaire: action.payload.questionnaire,
                step: 3,
            };
        case "QUESTIONNAIRE_DELETE_SUCCESS":
            return {
                ...state,
                questionnaires: state.questionnaires.filter((q) => q.id !== action.payload.id)
            };
        case "QUESTION_REMOVE_SUCCESS":
            return {
                ...state,
                questions: state.questions.filter((q) => q.id !== action.payload)
            };
        case "QUESTION_ADD_SUCCESS":
            const questions = [...state.questions, action.payload];
            return {
                ...state,
                questions: questions,
            };
        case "QUESTION_UPDATE_SUCCESS":
            const index = updateQuestions.findIndex((q) => q.id === action.payload.id);
            if (index != -1) {
                updateQuestions[index] = action.payload;
            }
            return {
                ...state,
                questions: updateQuestions,
            };
        case "SHOW_QUESTIONNAIRE":
            let questionnaireQuestions = [];
            action.payload.sheets.forEach(sheet => {
                questionnaireQuestions = questionnaireQuestions.concat(sheet.questions);
            });
            return {
                ...state,
                questionnaire: action.payload,
                step: 3,
                sheets: action.payload.sheets,
                questions: questionnaireQuestions,
            }
        case "SHOW_QUESTIONNAIRE_FORM":
            return {
                ...state,
                showForm: action.payload,
            }
        case "CLEAR_QUESTIONNAIRE_FORM":
            return {
                ...state,
                questionnaire: undefined,
                sheets: undefined,
                questions: [],
                step: 1,
            }
        case "QUESTIONNAIRE_RUN_SUCCESS":
            const questionnaireIndex = updateQuestionnaires.findIndex((q) => q.id === action.payload.id);
            if (questionnaireIndex !== -1) {
                updateQuestionnaires[questionnaireIndex] = action.payload;
            }
            return {
                ...state,
                questionnaires: updateQuestionnaires,
            }
        case "RUN_DELETE_SUCCESS":
            const runIndex = updateQuestionnaires.findIndex((q) => q.id === action.payload.questionnaire.id);
            if (runIndex !== -1) {
                updateQuestionnaires[runIndex].runs = updateQuestionnaires[runIndex].runs.filter(
                    (r) => r.id !== action.payload.run.id
                );
            }
            return {
                ...state,
                questionnaires: updateQuestionnaires,
            }
        default:
            return state;
    }
};

export default questionnaireReducer;