const initialState = {
  customPrompt: "",
  evidenceFormattingEnabled: false,
  questionnairesEnabled: false,
  customModelEnabled: false,
  showLibrary: false,
  evidenceFormattingPrompt: "",
  error: null,
  fetching: false,
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "START_FETCHING_SETTINGS":
      return {
        ...state,
        fetching: true,
      };
    case "GET_SETTINGS_SUCCESS":
      return {
        ...state,
        customPrompt: action.payload.custom_prompt,
        customModelEnabled: action.payload.custom_model,
        evidenceFormattingEnabled: action.payload.ef_enabled,
        questionnairesEnabled: action.payload.questionnaires_enabled,
        evidenceFormattingPrompt: action.payload.evidence_formatting_prompt,
        showLibrary: action.payload.show_library,
        useSearchAPI: action.payload.use_search_api,
        fetching: false,
      };
    case "GET_SETTINGS_ERROR":
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    case "START_SAVING_SETTINGS":
      return {
        ...state,
        fetching: true,
      };
    case "SAVE_SETTINGS_SUCCESS":
      return {
        ...state,
        customPrompt: action.payload.custom_prompt,
        customModelEnabled: action.payload.custom_model,
        evidenceFormattingEnabled: action.payload.ef_enabled,
        questionnairesEnabled: action.payload.questionnaires_enabled,
        evidenceFormattingPrompt: action.payload.evidence_formatting_prompt,
        showLibrary: action.payload.show_library,
        fetching: false,
      };
    case "SAVE_SETTINGS_ERROR":
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    default:
      return state;
  }
};

export default settingsReducer;
