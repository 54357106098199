import {Button, Form, Input, Upload} from "antd";
import {InboxOutlined} from "@ant-design/icons";
import React, {useState} from "react";
import {uploadQuestionnaire} from "../../redux/actions/questionnaireActions";
import {useDispatch} from "react-redux";

function Step1({chatId}) {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [file, setFile] = useState(null);

    const onFinish = async (values) => {
        await dispatch(uploadQuestionnaire(file, values.name, values.customer_name, chatId));
        form.resetFields();
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const beforeUpload = (file) => {
        setFile(file);
        return false;
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    return (
        <Form name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
              style={{ maxWidth: '70vw', }}
              initialValues={{ remember: true }}
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
        >
            <Form.Item
                label="Name"
                name="name"
                color='black !important'
                rules={[{ required: true, message: 'Please input name!', }]}
            >
                <Input placeholder="Enter Name for Questionnaire" />
            </Form.Item>
            <Form.Item
                label="Customer"
                name="customer_name"
                color='black !important'
                rules={[{ required: true, message: 'Please customer name!', }]}
            >
                <Input placeholder="Enter Customer Name" />
            </Form.Item>
            <Form.Item label="Excel File"
                       rules={[{ required: true, message: 'Please select file!', }]}>
                <Form.Item name="dragger" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                    <Upload.Dragger name="files" maxCount={1} beforeUpload={beforeUpload}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    </Upload.Dragger>
                </Form.Item>
            </Form.Item>
            <Form.Item
                wrapperCol={{ offset: 8, span: 16 }}
            >
                <Button type="primary" htmlType="submit">
                    Create
                </Button>
            </Form.Item>
        </Form>
    )
}

export default Step1;
