import {Table, Form, ConfigProvider, Button, Select, Collapse, Tooltip, Switch} from "antd";
import React, {useEffect, useState} from "react";
import {
    AddQuestion,
    getQuestionnaires,
    RemoveQuestionnaireQuestion,
    UpdateQuestion
} from "../../redux/actions/questionnaireActions";
import {useDispatch, useSelector} from "react-redux";
import {MdDeleteOutline} from "react-icons/md";

function Step3() {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const sheets = useSelector(state => state.questionnaire.sheets);
    const questionnaire = useSelector(state => state.questionnaire.questionnaire);
    const questions = useSelector(state => state.questionnaire.questions);
    const [modifiedQuestions, setModifiedQuestions] = useState([]);
    const [availableSheets, setAvailableSheets] = useState([]);
    const [enableRubric, setEnableRubric] = useState(false);
    const [tableColumns, setTableColumns] = useState([]);

    useEffect(() => {
        const newQuestions = questions.map((question) => {
            const found = sheets.find((sheet) => (sheet.id === question.sheet_id));
            if (found) {
                question.sheet = found;
                question.sheet_name = found.name;
            }
            return question;
        });
        setModifiedQuestions(newQuestions);

        if (sheets) {
            const useSheets = sheets.filter((sheet) => sheet.search && sheet.search === 'True');
            setAvailableSheets(useSheets);
        }
    },[questions, sheets]);

    const colLetter = (i) => {
        const m = i % 26;
        const c = String.fromCharCode(65 + m);
        const r = i - m;
        return r > 0
            ? `${colLetter((r - 1) / 26)}${c}`
            : c
    };

    const removeColumn = async (question, column) => {
        question[column] = 0;
        await dispatch(UpdateQuestion(question, questionnaire.id));
    };

    const displayColumn = (value, question, column, tooltip) => {
        if (!value) {
            return ''
        }
        return (
            <div className="d-flex flex-row">
                <div className="me-1">{colLetter(value - 1)}</div>
                <div style={{cursor: 'pointer'}} onClick={() => { removeColumn(question, column)}}>
                    <Tooltip title={tooltip}>
                        <MdDeleteOutline style={{ fontSize: '20px' }} />
                    </Tooltip>
                </div>
            </div>
        )
    };

    const columns = [
        {
            title: 'Question',
            dataIndex: 'content',
            key: 'content',
            className: 'text-bg-secondary'
        },
        {
            title: 'Sheet',
            dataIndex: 'sheet_name',
            key: 'sheet_name',
            className: 'text-bg-secondary'
        },
        {
            title: 'Question Location',
            children: [
                {
                    title: 'Row',
                    dataIndex: 'row',
                    key: 'row',
                    className: 'text-bg-secondary'
                },
                {
                    title: 'Column',
                    align: 'center',
                    dataIndex: 'column',
                    key: 'column',
                    className: 'text-bg-secondary',
                    render: (text) => (
                        colLetter(text - 1)
                    )
                },
            ]
        },
        {
            title: 'Actions',
            align: 'center',
            render: (_, record) => (
                <Tooltip title="Remove Question">
                    <a onClick={() => { removeQuestion(record) }}>
                        <MdDeleteOutline style={{ fontSize: '25px' }} />
                    </a>
                </Tooltip>
            )
        },
    ];

    const conditionalColumns = modifiedQuestions.some(q => q.yesNoColumn !== "None") ? [
        {
            title: 'Yes/No Column',
            align: 'center',
            dataIndex: 'yesNoColumn',
            key: 'yesNoColumn',
            render: (text, record) => (
                displayColumn(text, record, 'yesNoColumn', 'Remove Yes/No Column')
            )
        }
    ] : [];

    const rubricColumns = [
        {
            title: 'Control Scoring',
            children: [
                {
                    title: 'Rubric',
                    align: 'center',
                    dataIndex: 'rubric',
                    key: 'rubric',
                },
                {
                    title: 'Max Score',
                    align: 'center',
                    dataIndex: 'maxScore',
                    key: 'maxScore',
                },
            ],
        },
    ];

    useEffect(() => {
        let updatedColumns = columns.concat(conditionalColumns);
        if (enableRubric) {
            updatedColumns = updatedColumns.concat(rubricColumns);
        }
        setTableColumns(updatedColumns);
    }, [enableRubric, modifiedQuestions]);

    const removeQuestion = async (question) => {
        await dispatch(RemoveQuestionnaireQuestion(question.id, questionnaire.id));
    };

    const onFinish = async (values) => {
        await dispatch(AddQuestion(values, questionnaire.id));
        form.resetFields();
    }
    const onFinishFailed = (errorInfo) => {
        console.error('Failed:', errorInfo);
    };

    const addForm = (
        <Form name="basic" labelCol={{span: 8}} wrapperCol={{span: 16}}
              style={{maxWidth: '70vw'}}
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout="horizontal"
        >
            <Form.Item label="Sheet" name="sheet_id">
                <Select>
                    {
                        availableSheets.map((sheet) => {
                            return (<Select.Option value={sheet.id} key={sheet.id}>{sheet.name}</Select.Option>)
                        })
                    }
                </Select>
            </Form.Item>
            <Form.Item label="Row" name="row_index">
                <Select>
                    {
                        Array(200).fill(1).map((_, i) => {
                            return (<Select.Option value={i + 1} key={i}>{i + 1}</Select.Option>)
                        })
                    }
                </Select>
            </Form.Item>
            <Form.Item label="Column" name="column_index">
                <Select>
                    {
                        Array(26).fill(1).map((_, i) => {
                            return (<Select.Option value={i+1} key={i}>{colLetter(i)}</Select.Option>)
                        })
                    }
                </Select>
            </Form.Item>
            <Form.Item label="Yes/No Column" name="yesNoColumn">
                <Select>
                    <Select.Option value="">None</Select.Option>
                    {
                        Array(26).fill(1).map((_, i) => {
                            return (<Select.Option value={i+1} key={i}>{colLetter(i)}</Select.Option>)
                        })
                    }
                </Select>
            </Form.Item>
            <Form.Item label="Comment Column" name="commentColumn">
                <Select>
                    <Select.Option value="">None</Select.Option>
                    {
                        Array(26).fill(1).map((_, i) => {
                            return (<Select.Option value={i+1} key={i}>{colLetter(i)}</Select.Option>)
                        })
                    }
                </Select>
            </Form.Item>
            <Form.Item label="Excerpt Column" name="excerptColumn">
                <Select>
                    <Select.Option value="">None</Select.Option>
                    {
                        Array(26).fill(1).map((_, i) => {
                            return (<Select.Option value={i + 1} key={i}>{colLetter(i)}</Select.Option>)
                        })
                    }
                </Select>
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Add Question
                </Button>
            </Form.Item>
        </Form>
    );

    const toggleRubric = (checked) => {
        setEnableRubric(checked);
    };

    const finish = () => {
        dispatch({ type: 'SHOW_QUESTIONNAIRE_FORM', payload: false})
        dispatch(getQuestionnaires());
    };

    return (
        <div className="questionnaire">
            <div className="d-flex flex-row mb-3">
                <div>
                    <h1 className="text-black w-100">Questionnaire {questionnaire?.name} finalize questions.</h1>
                    <p className="text-black my-2">
                        Please review the questions we found. You can remove any questions that should not be answered and
                        you can add more questions that we didn't find automatically.
                    </p>
                </div>
                <div>
                    <Button type="primary" onClick={() => { finish() }}>Finish</Button>
                </div>
            </div>

            <ConfigProvider
                theme={{
                    components: {
                        Table: {
                            rowHoverBg: '#cccccc',
                            headerBg: '#cc0000'
                        },
                        Collapse: {
                            contentBg: '#ddd'
                        }
                    },
                }}
            >
                <div className="my-2">
                    <Switch onChange={toggleRubric}/> Show/Hide Control Scoring
                </div>
                <div className="my-2">
                    <Collapse items={[{
                        key: '1',
                        label: 'Don\'t see question that needs answered? Add one here',
                        children: addForm
                    }]}/>
                </div>
                <Table columns={tableColumns} dataSource={modifiedQuestions}/>
            </ConfigProvider>
        </div>
)
}

export default Step3;
