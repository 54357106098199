import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import authAxios from '../services/authAxios';
import { signOut } from '../redux/actions/authActions';
import Cookies from 'js-cookie';


const ProtectedRoutes = ({ element }) => {
    let isAuth= Cookies.get('isAuthenticated') === 'true' ? true : false;

    const [isAuthenticated, setIsAuthenticated] = useState(isAuth);
    const [isReady, setIsReady] = useState(false);
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect( () => {
        const setupInterceptor = () => {
            authAxios.interceptors.response.use(
                response => response,
                error => {
                    console.error(error)
                    if (error?.code === "ERR_CANCELED") throw error
                    if (error?.response?.status === 401) {
                        setIsAuthenticated(false);
                        dispatch(signOut());
                    }
                    return Promise.reject(error);
                }
            );
        };

        const checkAuthCookie = async () => {
            try {
                const response = await authAxios.get('get-user');
                if (response) {
                    setIsAuthenticated(true);
                    Cookies.set('isAuthenticated', 'true');
                    setupInterceptor();
                }
            } catch (err) {
                // no user is logged in
            } finally {
                setIsReady(true);
            }
        }
        if (!isAuthenticated) {
            setTimeout(() => {
                // need to wait to make sure the cookies are created after SSO/Oauth login
                checkAuthCookie();
            }, 500);
        } else {
            setupInterceptor();
            setIsReady(true);
        }
    }, [dispatch, isAuthenticated])

    if (!isReady) {
        return <div>Loading</div>;
    } else {
        return isAuthenticated ? (
            <Outlet/>
        ) : (
            <Navigate to="/login" state={{from: location}} replace/>
        );
    }
};

export default ProtectedRoutes;
