import { Table } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsersQuestions } from "../redux/actions/adminUsersAction";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import ChatLoader from "../Components/ChatInterface/ChatLoader";

const UserDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { questions, totalQuestions, fetching } = useSelector(
    (state) => state.adminUsers
  );

  useEffect(() => {
    dispatch(getUsersQuestions(id));
  }, []);

  const columns = [
    {
      title: "Question",
      dataIndex: "message_text",
    },
    {
      title: "Time",
      dataIndex: "created_at",
      render: (text) => {
        return dayjs(text).format("MMM-DD-YYYY hh:mm A");
      },
    },
  ];

  return (
    <>
      {fetching ? (
        <ChatLoader />
      ) : (
        <div>
          <h1>User's Questions</h1>
          <br />
          <h2>Total Question: {totalQuestions}</h2>
          <br />
          <Table columns={columns} dataSource={questions} />
        </div>
      )}
    </>
  );
};

export default UserDetails;