import { Amplify } from 'aws-amplify';
import React from "react";
import { Provider } from 'react-redux';
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MainLayout from "./Components/Layout/MainLayout";
import BuyCredits from "./Pages/BuyCredits";
import Contacts from "./Pages/Contacts";
import ForgotPassword from "./Pages/ForgotPassword";
import Loader from "./Pages/Loader";
import SignIn from "./Pages/SignIn";
import SignUp from "./Pages/SignUp";
import Verification from "./Pages/Verification";
import Vultr from './Pages/sso/vultr';
import awsconfig from './config/aws-export';
// import { PersistGate } from 'redux-persist/integration/react';
import Dashboard1 from './Pages/Dashboard1';
import DashboardHome from "./Pages/DashboardHome";
import Home from "./Pages/Home";
import PrivacyPolicy from "./Pages/Privacypolicy";
import Questionnaire from "./Pages/Questionnaire";
import ResetPassword from "./Pages/ResetPassword";
import Settings from './Pages/Settings';
import TeamDetails from './Pages/TeamDetails';
import TOS from "./Pages/Termsofservice";
import UserDetails from './Pages/UserDetails';
import { store } from './redux';
import ProtectedRoutes from "./routes/ProtectedRoute";
import KnowledgeBase from './Pages/KnowledgeBase';

Amplify.configure(awsconfig);

function App() {
  return (
    <Provider store={store}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<SignIn />} />
            <Route path="/auth" element={<Loader />} />
            <Route path="signup" element={<SignUp />} />
            <Route path="verification" element={<Verification />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/tos" element={<TOS />} />
            <Route path="/sso">
              <Route path="vultr" element={<Vultr />} />
            </Route>
            <Route element={<ProtectedRoutes />}>
              <Route path="/" element={<MainLayout />}>
                <Route path="/home" element={<DashboardHome />} />
                <Route path="/chat" element={<Dashboard1 />} />
                <Route path="/questionnaire" element={<Questionnaire />} />
                <Route path="/knowledge-base" element={<KnowledgeBase />} />
                {/* <Route path="/my-profile" element={<MyProfile />} /> */}
                <Route path="/contacts" element={<Contacts />} />
                <Route path="/user/:id" element={<UserDetails />} />
                <Route path="/team/:id" element={<TeamDetails />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/buy-credits" element={<BuyCredits />} />
                {/* <Route path="/payment" element={<Payment />} /> */}
                
              </Route>
            </Route>
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
          <ToastContainer />
        </Router>
      {/* </PersistGate> */}
    </Provider>
  );
}

export default App;
