import React, { useState } from 'react';
import { SlArrowDown, SlArrowUp } from "react-icons/sl";
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import AIMessageLoader from './AIMessageLoader';
import { useSelector } from 'react-redux';

export default function ListViewMessage({ content, evidence, isUser, avatar, userName, isEditable, onEdit, index}) {
    const [evidenceOpen, setEvidenceopen] = useState(false);
    const isQuestion = isUser;
    const formatting = useSelector(state => (state.message.messages[index]?.isContextFetching));
    if (evidence) {
        evidence.page_content = evidence?.page_content || [];
    };

    return (
        <div className={`message ${isUser ? "user-message" : "ai-message"}`}>
            {isQuestion
                ? <div className="message-content" style={{ wordWrap: "break-word" }}>
                    {content}
                </div>
                : <div>
                    {evidenceOpen
                        ? (<div>
                            <div className="message-content" style={{ wordWrap: "break-word" }}>
                                <Markdown remarkPlugins={[remarkGfm]}>{content}</Markdown>
                                <div style={{ paddingLeft: '98%' }}>
                                    <SlArrowUp onClick={(e) => setEvidenceopen(false)} />
                                </div>

                                {formatting
                                    ? <AIMessageLoader />
                                    : evidence.page_content.map((content, i) => <div key={i} className="message-evidence">
                                        <>
                                            <b>{evidence.metadata[i].filename}, {evidence.metadata[i].page ? "page" : "row"} #{evidence.metadata[i].page || evidence.metadata[i].row}</b>
                                            <Markdown remarkPlugins={[remarkGfm]} className={"context-container"}>{content}</Markdown>
                                        </>

                                    </div>)
                                }
                            </div>
                        </div>
                        )
                        : <React.Fragment>
                            <div className="message-content" style={{ wordWrap: "break-word" }}><Markdown remarkPlugins={[remarkGfm]}>{content}</Markdown></div>
                            <div style={{ paddingLeft: '98%' }}>
                                <SlArrowDown onClick={(e) => setEvidenceopen(true)} />
                            </div>
                        </React.Fragment>
                    }
                </div>

            }
        </div>
    )
}
