import authAxios from "../../services/authAxios";

export const setUser = (user) => ({
    type: 'SET_USER',
    payload: user,
});

export const clearUser = () => ({
    type: 'CLEAR_USER',
});

export const callSignIn = () => async (dispatch) => {
    try {
        const response = await authAxios.post('sign-in');
    } catch (error) {
        console.log('Error sign-in', error);
    }
};