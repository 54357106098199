import authAxios from "../../services/authAxios";
import {getFiles} from "./fileActions";
import questionnaire from "../../Pages/Questionnaire";
import {toast} from "react-toastify";

export const getQuestionnaires = (chatId) => async (dispatch) => {
    try {
        const response = await authAxios.get("questionnaire", {
            params: {
                chatId,
            },
            timeout: 5000
        });
        dispatch({ type: "GET_QUESTIONNAIRES_SUCCESS", payload: response.data });
    } catch (error) {
        dispatch({ type: "GET_QUESTIONNAIRES_ERROR", payload: error.message });
    }
};

export const uploadQuestionnaire = (file, name, customer_name, chatId) => async (dispatch, getState) => {
    try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('name', name);
        formData.append('customer_name', customer_name);
        if (chatId) {
            formData.append('chat_id', chatId);
        }
        const response = await authAxios.post('questionnaire', formData, {
            onUploadProgress: (progressEvent) => {
                dispatch({type: 'UPDATE_FILE_PROGRESS', payload: {
                    file,
                    progressEvent,
                    complete: false,
                    error: ''
                }});
            },
        });
        dispatch({type: 'UPLOAD_QUESTIONNAIRE_SUCCESS', payload: response.data});
        dispatch({type: 'UPDATE_FILE_PROGRESS', payload: {file, progressEvent: {}, complete: true, error: ''}});
    } catch (e) {
        console.error(e);
        dispatch({type: 'UPDATE_FILE_PROGRESS', payload: {file, progressEvent: {}, complete: true, error: ''}});
        dispatch({ type: 'UPLOAD_QUESTIONNAIRE_ERROR', payload: "Upload error" });
    }
};

export const step2Questionnaire = (values, questionnaire) => async (dispatch, getState) => {
    try {
        const response = await authAxios.put(`questionnaire/${questionnaire.id}/step2`, values);
        dispatch({type: 'STEP2_QUESTIONNAIRE_SUCCESS', payload: response.data});
    } catch (e) {
        console.error(e);
        dispatch({ type: 'GET_QUESTIONNAIRES_ERROR', payload: "Step 2 error" });
    }
};

export const RemoveQuestionnaireQuestion = (questionId, questionnaireId) => async (dispatch) => {
    try {
        await authAxios.delete(`questionnaire/${questionnaireId}/question/${questionId}`);
        dispatch({type: 'QUESTION_REMOVE_SUCCESS', payload: questionId});
    } catch (e) {
        console.error(e);
        dispatch({ type: 'GET_QUESTIONNAIRES_ERROR', payload: "Error removing question" });
    }
};

export const AddQuestion = (values, questionnaireId) => async (dispatch) => {
    try {
        const response = await authAxios.post(`questionnaire/${questionnaireId}/question/`, values);
        dispatch({type: 'QUESTION_ADD_SUCCESS', payload: response.data});
    } catch (e) {
        console.error(e);
        dispatch({ type: 'GET_QUESTIONNAIRES_ERROR', payload: "Error adding question" });
    }
};

export const UpdateQuestion = (question, questionnaireId) => async (dispatch) => {
    try {
        const response = await authAxios.put(`questionnaire/${questionnaireId}/question/${question.id}`, question);
        dispatch({type: 'QUESTION_UPDATE_SUCCESS', payload: response.data});
    } catch (e) {
        console.error(e);
        dispatch({ type: 'GET_QUESTIONNAIRES_ERROR', payload: "Error updating question" });
    }
};

export const ShowQuestionnaire = (questionnaire) => (dispatch) => {
    dispatch({ type: 'SHOW_QUESTIONNAIRE', payload: questionnaire })
}

export const RemoveQuestionnaire = (questionnaire) => async (dispatch) => {
    try {
        await authAxios.delete(`questionnaire/${questionnaire.id}`);
        dispatch({type: 'QUESTIONNAIRE_DELETE_SUCCESS', payload: questionnaire});
    } catch (e) {
        console.error(e);
        dispatch({ type: 'GET_QUESTIONNAIRES_ERROR', payload: "Error deleting questionnaire" });
    }
};

export const StartQuestionnaireRun = (questionnaire, selectedFileIds, name, chatSpecific, chatId) => async (dispatch) => {
    try {
        const data = {
            selectedFileIds,
            name
        };
        if (chatSpecific) {
            data.chatId = chatId;
        }
        const response = await authAxios.post(`questionnaire/${questionnaire.id}/run`, data);
        return dispatch({type: 'QUESTIONNAIRE_RUN_SUCCESS', payload: response.data});
    } catch (e) {
        console.error(e);
        dispatch({ type: 'GET_QUESTIONNAIRES_ERROR', payload: "Error running questionnaire" });
    }
};

export const DownloadQuestionnaire = (questionnaire, run_id) => async (dispatch) => {
    try {
        const response = await authAxios.get(`questionnaire/${questionnaire.id}/prepare/${run_id}`);
        const downloadFile = await authAxios.get(response.data.url, {
            responseType: 'blob'
        });
        toast.info("File ready starting download", {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 2000
        })

        // create file link in browser's memory
        const href = URL.createObjectURL(downloadFile.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', response.data.filename || 'file.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    } catch (e) {
        console.error(e);
        dispatch({ type: 'GET_QUESTIONNAIRES_ERROR', payload: "Error downloading questionnaire" });
    }
};

export const DeleteQuestionnaireRun = (questionnaire, run) => async (dispatch) => {
    try {
        await authAxios.delete(`questionnaire/${questionnaire.id}/run/${run.id}`);
        dispatch({type: 'RUN_DELETE_SUCCESS', payload: {questionnaire, run}});
    } catch (e) {
        console.error(e);
        dispatch({ type: 'GET_QUESTIONNAIRES_ERROR', payload: "Error deleting run" });
    }
}

export const CancelQuestionnaireRun = (questionnaire) => async (dispatch) => {
    try {
        await authAxios.post(`questionnaire/${questionnaire.id}/cancel`);
        dispatch({type: 'RUN_CANCEL_SUCCESS', payload: {}});
    } catch (e) {
        console.error(e);
        dispatch({ type: 'GET_QUESTIONNAIRES_ERROR', payload: "Error canceling run" });
    }
}
