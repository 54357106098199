import authAxios from "../../services/authAxios";

export const getUsersQuestions = (userId) => async (dispatch) => {
  dispatch({ type: "START_FETCHING_QUESTIONS" });
  try {
    const response = await authAxios.get(`get-user-questions/${userId}`);
    dispatch({
      type: "GET_QUESTIONS_SUCCESS",
      payload: {
        questions: response.data.questions,
        total: response.data.total,
      },
    });
  } catch (error) {
    dispatch({ type: "GET_QUESTIONS_ERROR", payload: error.message });
  }
};
