const initialData = {
  teams: [],
  currentTeam: null,
  currentTeamMembers: [],
  fetching: false,
  fetchingTeam: false,
  fetchingTeamMembers: false,
  error: null,
};

const adminTeamsReducer = (state = initialData, action) => {
  switch (action.type) {
    case "START_FETCHING_TEAMS":
      return {
        ...state,
        fetching: true,
        error: null,
      };
    case "START_FETCHING_TEAM":
      return {
        ...state,
        fetchingTeam: true,
        error: null,
      };
    case "START_FETCHING_TEAM_MEMBERS":
      return {
        ...state,
        fetchingTeamMembers: true,
        error: null,
      };
    case "GET_TEAMS_SUCCESS":
      return {
        ...state,
        teams: action.payload,
        fetchingTeam: false,
        error: null,
      };
    case "GET_TEAM_SUCCESS":
      return {
        ...state,
        currentTeam: action.payload,
        fetchingTeam: false,
        error: null,
      };
    case "GET_TEAM_MEMBERS_SUCCESS":
      return {
        ...state,
        currentTeamMembers: action.payload,
        fetchingTeamMembers: false,
        error: null,
      };

    case "GET_TEAMS_ERROR":
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };
    case "GET_TEAM_ERROR":
      return {
        ...state,
        fetchingTeam: false,
        error: action.payload,
      };
    case "GET_TEAM_MEMBERS_ERROR":
      return {
        ...state,
        fetchingTeamMembers: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default adminTeamsReducer;
