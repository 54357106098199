import authAxios from "../../services/authAxios";

export const getTeams = (queryParams) => async (dispatch) => {
  dispatch({ type: "START_FETCHING_TEAMS" });
  try {
    let response = null;
    if (!queryParams) {
      response = await authAxios.get("admin/teams");
    } else {
      if ("search" in queryParams) {
        response = await authAxios.get(
          `admin/teams?search=${queryParams.search}`
        );
      }
    }
    dispatch({ type: "GET_TEAMS_SUCCESS", payload: response.data });
  } catch (error) {
    dispatch({ type: "GET_TEAMS_ERROR", payload: error.message });
  }
};

export const getTeam = (teamId) => async (dispatch) => {
  dispatch({ type: "START_FETCHING_TEAMS" });
  try {
    const response = await authAxios.get(`admin/teams/${teamId}`);
    dispatch({ type: "GET_TEAM_SUCCESS", payload: response.data });
  } catch (error) {
    dispatch({ type: "GET_TEAM_ERROR", payload: error.message });
  }
};

export const getTeamMembers = (teamId) => async (dispatch) => {
  dispatch({ type: "START_FETCHING_TEAM_MEMBERS" });
  try {
    const response = await authAxios.get(`admin/teams/${teamId}/members`);
    dispatch({ type: "GET_TEAM_MEMBERS_SUCCESS", payload: response.data });
  } catch (error) {
    dispatch({ type: "GET_TEAM_MEMBERS_ERROR", payload: error.message });
  }
};

export const putTeam = (team) => async (dispatch) => {
  dispatch({ type: "START_UPDATING_TEAM" });
  try {
    const response = await authAxios.put(`admin/teams/${team.id}`, team);
    dispatch({ type: "PUT_TEAM_SUCCESS", payload: response.data });
  } catch (error) {
    dispatch({ type: "PUT_TEAM_ERROR", payload: error.message });
  }
}