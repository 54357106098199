import {useDispatch, useSelector} from "react-redux";
import React, {useState} from "react";
import { Button, Modal, Select, Form, Input, Upload } from 'antd';
import Step1 from "../Questionnaire/Step1";
import Step2 from "../Questionnaire/Step2";
import Step3 from "../Questionnaire/Step3";
import QuestionnaireRunModal from "./QuestionnaireRunModal";
import { toast } from "react-toastify";
import { getQuestionnaires, StartQuestionnaireRun } from "../../redux/actions/questionnaireActions";

function QuestionnaireForm({chatId}) {
    const dispatch = useDispatch();
    const step = useSelector(state => state.questionnaire.step);
    const questionnaire = useSelector(state => state.questionnaire.questionnaire);
    const isModalOpen = useSelector(state => state.questionnaire.showForm);

    const setShowForm = (val) => {
        dispatch({ type: 'SHOW_QUESTIONNAIRE_FORM', payload: val })
    }

    const handleOk = () => {
        setShowForm(false);
    };
    const handleCancel = () => {
        setShowForm(false);
    };

    const [selectedFileIds, setSelectedFileIds] = useState([]);

    const onSubmit = async (values) => {
        handleCancel(false);
        toast.info("Please wait a moment for the questionnaire to start processing.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 2000
        })
        await dispatch(StartQuestionnaireRun(questionnaire, selectedFileIds, new Date()))
        dispatch(getQuestionnaires());
        toast.info("Questionnaire is being processed, this may take a few minutes.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            hideProgressBar: true,
            autoClose: 2000
        })
    };


    return (
        <div id="questionnaireForm">
            <Modal zIndex='1000' width="80vw" title={questionnaire ? `Edit Questionnaire: Step ${step}` : `Create Questionnaire: Step ${step}`} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                {step === 1 && <Step1 chatId={chatId} />}
                {step === 2 && <Step2/>}
                {step === 3 && <QuestionnaireRunModal
                    selectedFileIds={selectedFileIds}
                    setSelectedFileIds={setSelectedFileIds}
                    onSubmit={onSubmit}
                />}
            </Modal>
        </div>
    )
}

export default QuestionnaireForm;