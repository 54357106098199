import authAxios from "../../services/authAxios";

export const getChats = () => async (dispatch) => {
    try {
        const response = await authAxios.get('chat');
        if (response.data?.length === 0) {
            dispatch(createChat({title:"New chat", chat_specific: false}))
        } else {
            dispatch({ type: 'GET_CHATS_SUCCESS', payload: response.data });
        }
    } catch (error) {
        dispatch({ type: 'GET_CHATS_ERROR', payload: error.message });
    }
};
export const getChatsForCS = () => async (dispatch) => {
    try {
        const response = await authAxios.get('rubric/chat');
        dispatch({ type: 'GET_CHATS_SUCCESS', payload: response.data });
    } catch (error) {
        dispatch({ type: 'GET_CHATS_ERROR', payload: error.message });
    }
};

export const getChatsForSectionScoring = () => async (dispatch) => {
    try {
        const response = await authAxios.get('section/chat');
        dispatch({ type: 'GET_CHATS_SUCCESS', payload: response.data });
    } catch (error) {
        dispatch({ type: 'GET_CHATS_ERROR', payload: error.message });
    }
};

export const createChat = (chat) => async (dispatch) => {
    try {
        const response = await authAxios.post('create-chat', chat);
        dispatch({ type: 'CREATE_CHAT_SUCCESS', payload: response.data });
        dispatch(getChats());
        return response.data;
    } catch (error) {
        dispatch({ type: 'CREATE_CHAT_ERROR', payload: error.message });
    }
};

export const createChatForCS = (chat) => async (dispatch) => {
    try {
        const response = await authAxios.post('rubric/create-chat', chat);
        dispatch({ type: 'CREATE_CHAT_SUCCESS', payload: response.data });
        dispatch(getChatsForCS());
    } catch (error) {
        dispatch({ type: 'CREATE_CHAT_ERROR', payload: error.message });
    }
};

export const createChatForSectionScoring = (chat) => async (dispatch) => {
    try {
        const response = await authAxios.post('section/create-chat', chat);
        dispatch({ type: 'CREATE_CHAT_SUCCESS', payload: response.data });
        dispatch(getChatsForSectionScoring());
    } catch (error) {
        dispatch({ type: 'CREATE_CHAT_ERROR', payload: error.message });
    }
};

export const updateChat = (chat) => async (dispatch) => {
    try {
        const response = await authAxios.put('chat', chat);
        dispatch({ type: 'UPDATE_CHAT_SUCCESS', payload: chat });
        dispatch(getChats());
    } catch (error) {
        dispatch({ type: 'UPDATE_CHAT_ERROR', payload: error.message });
    }
};

export const updateChatForCS = (chat) => async (dispatch) => {
    try {
        const response = await authAxios.put('rubric/chat', chat);
        dispatch({ type: 'UPDATE_CHAT_SUCCESS', payload: chat });
        dispatch(getChatsForCS());
    } catch (error) {
        dispatch({ type: 'UPDATE_CHAT_ERROR', payload: error.message });
    }
};

export const updateChatForSectionScoring = (chat) => async (dispatch) => {
    try {
        const response = await authAxios.put('section/chat', chat);
        dispatch({ type: 'UPDATE_CHAT_SUCCESS', payload: chat });
        dispatch(getChatsForSectionScoring());
    } catch (error) {
        dispatch({ type: 'UPDATE_CHAT_ERROR', payload: error.message });
    }
};

export const updateCurrentChat = (currentChat) => async (dispatch) => {
    dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: currentChat });
};

export const deleteChat = (chat) => async (dispatch) => {
    try {
        const response = await authAxios.delete('chat', {data:chat});
        dispatch({ type: 'DELETE_CHAT_SUCCESS', payload: {data:response.data, id: chat.chat_id} });
    } catch (error) {
        dispatch({ type: 'DELETE_CHAT_ERROR', payload: error.message });
    }
};

export const deleteChatForCS = (chat) => async (dispatch) => {
    try {
        const response = await authAxios.delete('rubric/chat', {data:chat});
        dispatch({ type: 'DELETE_CHAT_SUCCESS', payload: {data:response.data, id: chat.chat_id} });
    } catch (error) {
        dispatch({ type: 'DELETE_CHAT_ERROR', payload: error.message });
    }
};

export const deleteChatForSectionScoring = (chat) => async (dispatch) => {
    try {
        const response = await authAxios.delete('section/chat', {data:chat});
        dispatch({ type: 'DELETE_CHAT_SUCCESS', payload: {data:response.data, id: chat.chat_id} });
    } catch (error) {
        dispatch({ type: 'DELETE_CHAT_ERROR', payload: error.message });
    }
};

export const setContextFetchingFalse = () => (dispatch) => {
    dispatch({ type: "SET_ALL_CONTEXT_FETCHING_FALSE" });
};
