const initialState = {
  questions: [],
  totalQuestions: 0,
  error: null,
  fetching: false,
};

const adminUsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "START_FETCHING_QUESTIONS":
      return {
        ...state,
        error: null,
        fetching: true,
      };
    case "GET_QUESTIONS_SUCCESS":
      return {
        ...state,
        questions: action.payload.questions,
        totalQuestions: action.payload.total,
        error: null,
        fetching: false,
      };
    case "GET_QUESTIONS_ERROR":
      return {
        ...state,
        questions: [],
        error: action.payload,
        fetching: false,
      };
    default:
      return state;
  }
};

export default adminUsersReducer;