import { applyMiddleware, combineReducers, createStore } from "redux";
// import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
import thunk from "redux-thunk";
import adminTeamsReducer from "./reducers/AdminTeamsReducer";
import adminUsersReducer from "./reducers/adminUserReducer";
import chatMessageReducer from "./reducers/chatMessageReducer";
import chatReducer from "./reducers/chatReducer";
import customerReducer from "./reducers/customerReducer";
import fileReducer from "./reducers/fileReducer";
import libraryReducer from "./reducers/libraryReducer";
import questionnaireReducer from "./reducers/questionnaireReducer";
import receiptReducer from "./reducers/receiptReducer";
import settingsReducer from "./reducers/settingsReducer";
import templateReducer from "./reducers/templateReducer";
import userReducer from "./reducers/userReducer";
import workspaceReducer from "./reducers/workspaceReducer";

// const persistConfig = {
//   key: 'root',
//   storage,
// };

const rootReducer = combineReducers({
  user: userReducer,
  file: fileReducer,
  chat: chatReducer,
  template: templateReducer,
  message: chatMessageReducer,
  receipt: receiptReducer,
  customer: customerReducer,
  workspace: workspaceReducer,
  adminUsers: adminUsersReducer,
  adminTeams: adminTeamsReducer,
  settings: settingsReducer,
  library: libraryReducer,
  questionnaire: questionnaireReducer,
});

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// const store = createStore(
//   persistedReducer,
//   applyMiddleware(thunk)
// );

// const persistor = persistStore(store);

// export { store, persistor };

const store = createStore(rootReducer, applyMiddleware(thunk));

export { store };
