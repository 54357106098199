import React, {useEffect, useState} from "react";
import { Button, Modal, Select, Form, Input } from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {getTeamSaml, inviteWorkspace, removeTeamSaml, setupTeamSaml} from "../../redux/actions/workspaceActions";
// import './users.css';

function SamlDialogBox() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { Option } = Select;
    const samlSettings = useSelector((state) => state.workspace.samlSettings);

    useEffect(() => {
        dispatch(getTeamSaml())
    }, [dispatch]);

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const onFinish = (values) => {
        dispatch(setupTeamSaml(values));
        setIsModalOpen(false);
        form.resetFields();
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const showModalRemove = () => {
        setIsRemoveModalOpen(true);
    };
    const handleOkRemove = () => {
        setIsRemoveModalOpen(false);
    };
    const handleCancelRemove = () => {
        setIsRemoveModalOpen(false);
    };
    const onFinishRemove = (values) => {
        dispatch(removeTeamSaml());
        setIsRemoveModalOpen(false);
    };
    const onFinishFailedRemove = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
    <div>
        { !samlSettings.setup ? (
            <div className="add_saml">
                <div
                    className="saml_btn"
                    onClick={showModal}
                    style={{
                        background: '#4575fc',
                        padding: '10px',
                        borderRadius: '8px',
                        fontWeight: '500'
                    }}>Setup SAML
                </div>
                <Modal title="Setup SAML" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                    <Form name="basic" labelCol={{span: 8}} wrapperCol={{span: 16}}
                          style={{maxWidth: 600,}}
                          initialValues={{remember: true}}
                          form={form}
                          onFinish={onFinish}
                          onFinishFailed={onFinishFailed}
                          autoComplete="off"
                    >
                        <Form.Item
                            label="Metadata Url / Entity ID"
                            name="metadataUrl"
                            rules={[
                                {required: true, message: 'Please input metadata url!',},]}
                        >
                            <Input placeholder="https://test.com/saml/metadata"/>
                        </Form.Item>

                        <Form.Item
                            wrapperCol={{offset: 8, span: 16}}
                        >
                            <Button type="primary" htmlType="submit">
                                Save
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        ) : (
            <div className="add_saml">
                <div
                    className="saml_btn"
                    onClick={showModalRemove}
                    style={{
                        background: '#4575fc',
                        padding: '10px',
                        borderRadius: '8px',
                        fontWeight: '500'
                    }}>Remove SAML
                </div>
                <Modal title="Remove SAML" open={isRemoveModalOpen} onOk={handleOkRemove} onCancel={handleCancelRemove}>
                    <Form name="basic" labelCol={{span: 8}} wrapperCol={{span: 16}}
                          style={{maxWidth: 600,}}
                          initialValues={{remember: true}}
                          onFinish={onFinishRemove}
                          onFinishFailed={onFinishFailedRemove}
                          autoComplete="off"
                    >
                        <p style={{color: '#222'}}>Are you sure you want to remove your SAML connection?</p>
                        <Form.Item
                            wrapperCol={{offset: 8, span: 16}}
                        >
                            <Button type="text" onClick={handleCancelRemove}>
                                Cancel
                            </Button>
                            <Button type="primary" htmlType="submit">
                                Remove SAML
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        )}
    </div>
    )
}

export default SamlDialogBox;
