import authAxios from "../../services/authAxios";

export const getSettings = () => async (dispatch) => {
  dispatch({ type: "START_FETCHING_SETTINGS" });
  try {
    const response = await authAxios.get("get-settings");
    dispatch({
      type: "GET_SETTINGS_SUCCESS",
      payload: response.data,
    });
  } catch (error) {
    dispatch({ type: "GET_SETTINGS_ERROR", payload: error.message });
  }
};

export const updateSettings = (data) => async (dispatch) => {
  dispatch({ type: "START_SAVING_SETTINGS" });
  try {
    const response = await authAxios.post("update-settings", data);
    dispatch({
      type: "SAVE_SETTINGS_SUCCESS",
      payload: response.data,
    });
  } catch (error) {
    dispatch({ type: "SAVE_SETTINGS_ERROR", payload: error.message });
  }
};
