import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
// import authAxios from './services/authAxios';
import * as Sentry from "@sentry/react";

// only enable in Production, all developers should set this value REACT_APP_DISABLE_SENTRY to true
if (!process.env.REACT_APP_DISABLE_SENTRY) {
    Sentry.init({
        dsn: "https://83fa41036e4be4dec52c4e43254cd780@o4507654492258304.ingest.us.sentry.io/4507693816020992",
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.browserProfilingIntegration(),
            Sentry.replayIntegration(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/api\.docubark\.com\/api/],
        // Profiling
        profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);