import React from "react";
import user from "../assets/Images/Avatar/new_user.png";
import { useSelector } from "react-redux";

function Topbar() {
  const { userData } = useSelector((state) => state.user);
  return (
    <div className="topbar_nav_item">
      <div className="logo">
        <h2>DocuBark</h2>
      </div>
      <ul className="top_nav_list">
        <div className="user">
          <p>
            {userData?.name ? `${userData?.name} | ` : ""}
            {userData?.email}
          </p>
          {/* <img style={{width: '40px'}} src={user} alt="" /> */}
        </div>
      </ul>
    </div>
  );
}

export default Topbar;
